export default function Hero() {
  return (
    <>
      <section className="AboutHero CompanyHero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div class="text-center">
                <div class="Hero-Content gap-24">
                  <div class="Frame-Route">
                    <h4>
                      <img src="/img/aboutsubtract.svg" />
                      OUR COMPANIES
                    </h4>
                  </div>
                  <div class="Content">
                    <div>
                      <h4>Our Subsidiaries</h4>
                    </div>
                    <div>
                      <p>
                        Explore our diverse family of subsidiaries, each
                        excelling in their respective fields and united by a
                        shared commitment to innovation, quality, and customer
                        satisfaction. Discover how our subsidiaries are driving
                        progress and delivering exceptional value worldwide.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
