import Hero from "./section/Hero";
import LineChart from "./section/LineChart";
import DonutChart from "./section/DonutChart";
const Stock = () => {
  return (
    <>
      <Hero />
      <section className="Chart">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-sm-12">
              <LineChart />
            </div>
            <div className="col-lg-3 col-sm-12">
              <DonutChart />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stock;
