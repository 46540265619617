import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const token = process.env.REACT_APP_PRESS_API_TOKEN;
const url = process.env.REACT_APP_PRESS_API_ENDPOINT;

const Press = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/iminings?populate=*&filters[category][$eq]=PressRelease&sort=publishedDate:desc`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data.slice(0, 3));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);
  return (
    <>
      <section className="Press" id="press">
        <div className="container">
          <video autoPlay loop>
            <source src="/video/press.mp4" type="video/mp4" />
          </video>
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="left-content">
                <h4>
                  <img src="/img/aboutsubtract.svg" />
                  Press release
                </h4>
                <h1>
                  IMINING TECHNOLOGIES INVESTS IN NEXT-GENERATION SOLUTIONS
                  ACROSS ALL INDUSTRY
                </h1>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="right-content">
                <div className="videothumbnail">
                  <Link to="" onClick={() => setModalShow(true)}>
                    <img src="/img/video.png" />
                    <h4>IMINING Technologies intro</h4>
                  </Link>
                  {/* <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  /> */}
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-12">
              <div className="horizontimeline">
                <ul className="list-inline">
                  {data.slice(0, 9).map((press) => (
                    <li>
                      <label>
                        {moment(press.attributes.publishedDate).format(
                          "MMMM DD, YYYY"
                        )}
                      </label>
                      <p>{press.attributes.title}</p>
                      <Link to={`/press-release-detail/${press.id}`}>
                        learn more
                        <img src="/img/arrowup.svg" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 text-center-div mt-5">
              <Link
                style={{
                  border: "1px solid #000",
                  fontFamily: "Inter",
                  padding: "9px 30px",
                  textDecoration: "none",
                  width: "fit-content",
                }}
                to="/press-release"
                className="press-btn send-mess"
              >
                View All
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Press;

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        <iframe
          width="100%"
          height="415"
          src="https://www.youtube.com/embed/K2EmdIUqjYk?si=gYYJGY4ThayZfYnJ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </Modal.Body>
    </Modal>
  );
}
