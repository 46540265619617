import React, { useEffect, useRef } from 'react';
import { Application } from '@splinetool/runtime';

const Spline = () => {
  const canvasRef = useRef(null);
  const appRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const app = new Application(canvas);
    appRef.current = app;

    // Load your spline scene
    app.load('https://prod.spline.design/FKnHeRH2VgGVc41G/scene.splinecode');


    
    // Disable pinch-to-zoom
    canvas.addEventListener('touchmove', e => {
      e.preventDefault();
    }, { passive: false });
    
    // Cleanup on component unmount
    return () => {
      if (appRef.current && typeof appRef.current.destroy === 'function') {
        appRef.current.destroy();
      }
    };
  }, []);

  return <canvas  ref={canvasRef} id="canvas3d" style={{ touchAction: 'none' ,height:"400px !important" }} />;
};

export default Spline;
