import { Link } from "react-router-dom";
const Mission = () => {
  return (
    <>
      <section className="Mission" id="mission">
        <img src="/img/missionlogo.svg" className="missionlogo" />

        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-sm-12">
              <img src="/img/mission1.svg" className="mission1" />
            </div>
            <div className="col-lg-7 col-sm-12">
              <div className="content">
                <h4>
                  <img src="/img/aboutsubtract.svg" />
                  Mission
                </h4>
                <h1>
                  CRAFTING FUTURE-TECH FOR POSITIVE IMPACT IS OUR MISSION.
                </h1>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <img src="/img/mission2.svg" className="mission2" />
            </div>
          </div>

          <div className="row align-items-center secondrow">
            <div className="col-lg-4 col-sm-12 text-end">
              <img src="/img/mission3.svg" className="mission3" />
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="content">
                <p>
                  Our mission is to pioneer the future of technology by
                  acquiring, building, and managing innovative companies that
                  harness the power of Artificial Intelligence. We are dedicated
                  to creating hyper-scalable solutions that revolutionize
                  industries and propel global advancement.
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12 text-end">
              <img src="/img/mission4.svg" className="mission4" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mission;
