export default function Hero() {
  return (
    <>
      <section className="AboutHero DirectorHero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div class="text-center">
                <div class="Hero-Content gap-24">
                  <div class="Frame-Route">
                    <h4>
                      <img src="/img/aboutsubtract.svg" />
                      Team
                    </h4>
                  </div>
                  <div class="Content">
                    <div>
                      <h4>CORPORATE GOVERNANCE</h4>
                    </div>
                    <div>
                      <p>
                        At iMining Technologies, we uphold high standards of
                        governance and ethics across our operations. We believe
                        that strong stewardship and effective management are
                        essential for our business and crucial to our
                        shareholders, employees, and other stakeholders. Our
                        Board of Directors, elected annually by shareholders,
                        oversees our business management with the aim of
                        maximizing shareholder value. Our corporate governance
                        policies and practices ensure that our Board meets its
                        statutory mandate to supervise our business and affairs
                        with the highest ethical standards and in the best
                        interests of all stakeholders.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
