import Hero from "./section/Hero";
// import Interviews from "./section/Interviews";
import Interviews from "../investor/section/Interviews";
const InterviewsPage = () => {
  return (
    <>
      <Hero />
      <Interviews />
    </>
  );
};

export default InterviewsPage;
