import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
const url = process.env.REACT_APP_PRESS_API_ENDPOINT;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ press }) => {
  return (
    <li>
      <label>
        {press ? (
          moment(press.attributes.publishedDate).format("MMMM DD, YYYY")
        ) : (
          <Skeleton count={1} />
        )}
      </label>
      <p>{press ? press.attributes.title : <Skeleton count={3} />}</p>
      {press ? (
        <Link
          style={{ color: "#9770ff" }}
          to={`/press-release-detail/${press.id}`}
        >
          learn more
          <img src="/img/arrowup.svg" />
        </Link>
      ) : (
        <Skeleton count={1} />
      )}
    </li>

    // <div className="col-lg-3 col-md-6 col-sm-12">
    //     <div className="content">
    //         <div>
    //             { press? <img src={press.attributes.media?.data?.attributes?.url ? `${url}${press.attributes.media?.data?.attributes?.url}` : '/img/imining-placeholder.jpg'} className="w-100" /> : <Skeleton count={1} style={{ width: '100%', height: '160px' }} />}
    //         </div>
    //         <div>
    //             <label className="mt-3 mb-2" style={{color:"#fff"}}>
    //                 { press ? moment(press.attributes.createdAt).format("MMMM DD, YYYY") : <Skeleton count={1} />}
    //             </label>
    //             <p style={{color:"#fff"}}>
    //                 { press ?  press.attributes.title.slice(0,100) : <Skeleton count={3} /> }
    //             </p>
    //             {
    //                 press ? (
    //                     <Link style={{color:"#9770ff"}} to={`/press-release-detail/${press.id}`}>
    //                         learn more
    //                         <img src="/img/arrowup.svg" />
    //                     </Link>
    //                 ) : <Skeleton count={1}/>
    //             }

    //         </div>
    //     </div>
    // </div>
  );
};
