import { Link } from "react-router-dom";

import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

const token = process.env.REACT_APP_PRESS_API_TOKEN;
const url = process.env.REACT_APP_PRESS_API_ENDPOINT;

export default function Interviews() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${url}/api/i-mining-interviews?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Bilal", response);
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);
// debugger
  return (
    <>
      <section className="Interviews">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <h4>Latest Interviews</h4>
              </div>
            </div>
            <div className="col-lg-8 col-sm-12">
              <ul className="list-unstyled interviewlist">
                {data.slice(0, 2).map((press) => (
                  <li>
                    <Link to={`/press-release-detail/${press.id}`}>
                      <div className="image">
                      <img src="/img/interview1.png" />
                        {/* {press?.attributes?.media?.data?.[0].attributes?.url ? (
                          <img
                            src={`${url}${press.attributes.media.data.attributes.url}`}
                            className="w-100"
                          />
                        ) : null} */}
                      </div>
                      <div className="content">
                        <label>{press.attributes.category}</label>
                        <h4>{press.attributes.title}</h4>
                        <h5>{press.attributes.shortDescription}</h5>
                        <span>
                          {moment(press.attributes.createdAt).format(
                            "MMMM DD, YYYY"
                          )}
                        </span>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
              {/* <ul className="list-unstyled interviewlist">
                <li>
                  <Link to="">
                    <div className="image">
                      <img src="/img/interview1.png" />
                    </div>
                    <div className="content">
                      <label>Category Title</label>
                      <h4>
                        Lorem ipsum dolor sit amet consectetur. Risus eu
                        ultrices dolor.
                      </h4>
                      <h5>By Manish Yadav in Random Category</h5>
                      <span>12th of July 2024</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <div className="image">
                      <img src="/img/interview2.png" />
                    </div>
                    <div className="content">
                      <label>Category Title</label>
                      <h4>
                        Lorem ipsum dolor sit amet consectetur. Risus eu
                        ultrices dolor.
                      </h4>
                      <h5>By Manish Yadav in Random Category</h5>
                      <span>12th of July 2024</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <div className="image">
                      <img src="/img/interview3.png" />
                    </div>
                    <div className="content">
                      <label>Category Title</label>
                      <h4>
                        Lorem ipsum dolor sit amet consectetur. Risus eu
                        ultrices dolor.
                      </h4>
                      <h5>By Manish Yadav in Random Category</h5>
                      <span>12th of July 2024</span>
                    </div>
                  </Link>
                </li>
              </ul> */}
            </div>
            <div className="col-lg-4 col-sm-12">
              <img src="/img/interviewposter.svg" className="w-100" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
