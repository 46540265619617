export default function Directors() {
  return (
    <>
      <section className="Directors">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="heading">
                <h4>Board of Directors</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="team card"
                style={{
                  display: "inline-block",
                  width: "100%",
                }}
                tabIndex="-1"
              >
                <div className="img">
                  <img src="/img/brad.jpeg" />
                </div>
                <div className="card-content">
                  <h4>Brad McGill</h4>
                  <ul className="list-inline">
                    <li style={{ display: "none" }}>
                      <a className="twitter" href="/">
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Social icon">
                            <path
                              d="M7.55016 22.2617C16.6045 22.2617 21.5583 14.7584 21.5583 8.25358C21.5583 8.04264 21.5536 7.82702 21.5442 7.61608C22.5079 6.91918 23.3395 6.05597 24 5.06702C23.1025 5.46632 22.1496 5.7271 21.1739 5.84045C22.2013 5.22463 22.9705 4.25719 23.3391 3.11749C22.3726 3.69028 21.3156 4.09433 20.2134 4.31233C19.4708 3.52328 18.489 3.00084 17.4197 2.82577C16.3504 2.6507 15.2532 2.83277 14.2977 3.34382C13.3423 3.85486 12.5818 4.66642 12.1338 5.65303C11.6859 6.63964 11.5754 7.74633 11.8195 8.80202C9.86249 8.70381 7.94794 8.19542 6.19998 7.30982C4.45203 6.42421 2.90969 5.18116 1.67297 3.66124C1.0444 4.74496 0.852057 6.02736 1.13503 7.24781C1.418 8.46826 2.15506 9.53517 3.19641 10.2317C2.41463 10.2069 1.64998 9.9964 0.965625 9.61764V9.67858C0.964925 10.8159 1.3581 11.9183 2.07831 12.7985C2.79852 13.6787 3.80132 14.2823 4.91625 14.5067C4.19206 14.7048 3.43198 14.7337 2.69484 14.5911C3.00945 15.5692 3.62157 16.4246 4.44577 17.0381C5.26997 17.6515 6.26512 17.9923 7.29234 18.013C5.54842 19.3828 3.39417 20.1258 1.17656 20.1223C0.783287 20.1217 0.390399 20.0976 0 20.0501C2.25286 21.4955 4.87353 22.2631 7.55016 22.2617Z"
                              fill="#98A2B3"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        className="linkedin"
                        href="https://www.linkedin.com/in/brad-mcgill-25594146?utm_source=share&utm_campaign=share_via&utm_content=profile"
                        target="_blank"
                      >
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Group">
                            <path
                              d="M22.2234 0.511719H1.77187C0.792187 0.511719 0 1.28516 0 2.24141V22.7773C0 23.7336 0.792187 24.5117 1.77187 24.5117H22.2234C23.2031 24.5117 24 23.7336 24 22.782V2.24141C24 1.28516 23.2031 0.511719 22.2234 0.511719ZM7.12031 20.9633H3.55781V9.50703H7.12031V20.9633ZM5.33906 7.94609C4.19531 7.94609 3.27188 7.02266 3.27188 5.88359C3.27188 4.74453 4.19531 3.82109 5.33906 3.82109C6.47813 3.82109 7.40156 4.74453 7.40156 5.88359C7.40156 7.01797 6.47813 7.94609 5.33906 7.94609ZM20.4516 20.9633H16.8937V15.3945C16.8937 14.068 16.8703 12.357 15.0422 12.357C13.1906 12.357 12.9094 13.8055 12.9094 15.3008V20.9633H9.35625V9.50703H12.7687V11.0727H12.8156C13.2891 10.1727 14.4516 9.22109 16.1813 9.22109C19.7859 9.22109 20.4516 11.593 20.4516 14.6773V20.9633Z"
                              fill="#98A2B3"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li style={{ display: "none" }}>
                      <a className="dribble" href="/">
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Social icon">
                            <path
                              clipRule="evenodd"
                              d="M12 0.511719C5.37527 0.511719 0 5.88699 0 12.5117C0 19.1365 5.37527 24.5117 12 24.5117C18.6117 24.5117 24 19.1365 24 12.5117C24 5.88699 18.6117 0.511719 12 0.511719ZM19.9262 6.04317C21.3579 7.78721 22.217 10.0128 22.243 12.4206C21.9046 12.3556 18.5206 11.6657 15.1106 12.0952C15.0325 11.926 14.9675 11.7438 14.8894 11.5616C14.6811 11.0671 14.4469 10.5595 14.2126 10.0779C17.9869 8.54212 19.705 6.32951 19.9262 6.04317ZM12 2.28179C14.603 2.28179 16.9848 3.25793 18.7939 4.85879C18.6117 5.1191 17.0629 7.18851 13.4186 8.55509C11.7397 5.4705 9.87855 2.94556 9.5922 2.5551C10.3601 2.37289 11.1671 2.28179 12 2.28179ZM7.63995 3.24491C7.91325 3.60933 9.73538 6.14729 11.4404 9.16679C6.65076 10.4423 2.42083 10.4163 1.96529 10.4163C2.62907 7.24057 4.77657 4.59848 7.63995 3.24491ZM1.74404 12.5248C1.74404 12.4206 1.74404 12.3165 1.74404 12.2124C2.18655 12.2254 7.15835 12.2905 12.2733 10.7547C12.5727 11.3273 12.846 11.913 13.1063 12.4987C12.9761 12.5377 12.8329 12.5768 12.7028 12.6158C7.41865 14.3208 4.60738 18.9802 4.3731 19.3707C2.7462 17.5616 1.74404 15.1538 1.74404 12.5248ZM12 22.7677C9.6312 22.7677 7.44469 21.9607 5.71367 20.6071C5.89588 20.2297 7.97827 16.2211 13.757 14.2037C13.783 14.1907 13.7961 14.1907 13.8221 14.1777C15.2668 17.913 15.8525 21.0496 16.0087 21.9477C14.7722 22.4813 13.4186 22.7677 12 22.7677ZM17.7136 21.0106C17.6096 20.3859 17.0629 17.3924 15.7223 13.7091C18.9371 13.2015 21.7484 14.0345 22.0998 14.1517C21.6573 17.002 20.0173 19.4618 17.7136 21.0106Z"
                              fill="#98A2B3"
                              fillRule="evenodd"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="team card"
                style={{
                  display: "inline-block",
                  width: "100%",
                }}
                tabIndex="-1"
              >
                <div className="img">
                  <img src="/img/Carlton.jpeg" />
                </div>
                <div className="card-content">
                  <h4>Carlton Griffith</h4>
                  <ul className="list-inline">
                    <li style={{ display: "none" }}>
                      <a className="twitter" href="/">
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Social icon">
                            <path
                              d="M7.55016 22.2617C16.6045 22.2617 21.5583 14.7584 21.5583 8.25358C21.5583 8.04264 21.5536 7.82702 21.5442 7.61608C22.5079 6.91918 23.3395 6.05597 24 5.06702C23.1025 5.46632 22.1496 5.7271 21.1739 5.84045C22.2013 5.22463 22.9705 4.25719 23.3391 3.11749C22.3726 3.69028 21.3156 4.09433 20.2134 4.31233C19.4708 3.52328 18.489 3.00084 17.4197 2.82577C16.3504 2.6507 15.2532 2.83277 14.2977 3.34382C13.3423 3.85486 12.5818 4.66642 12.1338 5.65303C11.6859 6.63964 11.5754 7.74633 11.8195 8.80202C9.86249 8.70381 7.94794 8.19542 6.19998 7.30982C4.45203 6.42421 2.90969 5.18116 1.67297 3.66124C1.0444 4.74496 0.852057 6.02736 1.13503 7.24781C1.418 8.46826 2.15506 9.53517 3.19641 10.2317C2.41463 10.2069 1.64998 9.9964 0.965625 9.61764V9.67858C0.964925 10.8159 1.3581 11.9183 2.07831 12.7985C2.79852 13.6787 3.80132 14.2823 4.91625 14.5067C4.19206 14.7048 3.43198 14.7337 2.69484 14.5911C3.00945 15.5692 3.62157 16.4246 4.44577 17.0381C5.26997 17.6515 6.26512 17.9923 7.29234 18.013C5.54842 19.3828 3.39417 20.1258 1.17656 20.1223C0.783287 20.1217 0.390399 20.0976 0 20.0501C2.25286 21.4955 4.87353 22.2631 7.55016 22.2617Z"
                              fill="#98A2B3"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        className="linkedin"
                        href="https://www.linkedin.com/in/carlton-griffith-917bba29/?utm_source=share&utm_campaign=share_via&utm_content=profile"
                        target="_blank"
                      >
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Group">
                            <path
                              d="M22.2234 0.511719H1.77187C0.792187 0.511719 0 1.28516 0 2.24141V22.7773C0 23.7336 0.792187 24.5117 1.77187 24.5117H22.2234C23.2031 24.5117 24 23.7336 24 22.782V2.24141C24 1.28516 23.2031 0.511719 22.2234 0.511719ZM7.12031 20.9633H3.55781V9.50703H7.12031V20.9633ZM5.33906 7.94609C4.19531 7.94609 3.27188 7.02266 3.27188 5.88359C3.27188 4.74453 4.19531 3.82109 5.33906 3.82109C6.47813 3.82109 7.40156 4.74453 7.40156 5.88359C7.40156 7.01797 6.47813 7.94609 5.33906 7.94609ZM20.4516 20.9633H16.8937V15.3945C16.8937 14.068 16.8703 12.357 15.0422 12.357C13.1906 12.357 12.9094 13.8055 12.9094 15.3008V20.9633H9.35625V9.50703H12.7687V11.0727H12.8156C13.2891 10.1727 14.4516 9.22109 16.1813 9.22109C19.7859 9.22109 20.4516 11.593 20.4516 14.6773V20.9633Z"
                              fill="#98A2B3"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li style={{ display: "none" }}>
                      <a className="dribble" href="/">
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Social icon">
                            <path
                              clipRule="evenodd"
                              d="M12 0.511719C5.37527 0.511719 0 5.88699 0 12.5117C0 19.1365 5.37527 24.5117 12 24.5117C18.6117 24.5117 24 19.1365 24 12.5117C24 5.88699 18.6117 0.511719 12 0.511719ZM19.9262 6.04317C21.3579 7.78721 22.217 10.0128 22.243 12.4206C21.9046 12.3556 18.5206 11.6657 15.1106 12.0952C15.0325 11.926 14.9675 11.7438 14.8894 11.5616C14.6811 11.0671 14.4469 10.5595 14.2126 10.0779C17.9869 8.54212 19.705 6.32951 19.9262 6.04317ZM12 2.28179C14.603 2.28179 16.9848 3.25793 18.7939 4.85879C18.6117 5.1191 17.0629 7.18851 13.4186 8.55509C11.7397 5.4705 9.87855 2.94556 9.5922 2.5551C10.3601 2.37289 11.1671 2.28179 12 2.28179ZM7.63995 3.24491C7.91325 3.60933 9.73538 6.14729 11.4404 9.16679C6.65076 10.4423 2.42083 10.4163 1.96529 10.4163C2.62907 7.24057 4.77657 4.59848 7.63995 3.24491ZM1.74404 12.5248C1.74404 12.4206 1.74404 12.3165 1.74404 12.2124C2.18655 12.2254 7.15835 12.2905 12.2733 10.7547C12.5727 11.3273 12.846 11.913 13.1063 12.4987C12.9761 12.5377 12.8329 12.5768 12.7028 12.6158C7.41865 14.3208 4.60738 18.9802 4.3731 19.3707C2.7462 17.5616 1.74404 15.1538 1.74404 12.5248ZM12 22.7677C9.6312 22.7677 7.44469 21.9607 5.71367 20.6071C5.89588 20.2297 7.97827 16.2211 13.757 14.2037C13.783 14.1907 13.7961 14.1907 13.8221 14.1777C15.2668 17.913 15.8525 21.0496 16.0087 21.9477C14.7722 22.4813 13.4186 22.7677 12 22.7677ZM17.7136 21.0106C17.6096 20.3859 17.0629 17.3924 15.7223 13.7091C18.9371 13.2015 21.7484 14.0345 22.0998 14.1517C21.6573 17.002 20.0173 19.4618 17.7136 21.0106Z"
                              fill="#98A2B3"
                              fillRule="evenodd"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="team card"
                style={{
                  display: "inline-block",
                  width: "100%",
                }}
                tabIndex="-1"
              >
                <div className="img">
                  <img src="/img/Saleem.png" />
                </div>
                <div className="card-content">
                  <h4>Saleem Moosa</h4>
                  <ul className="list-inline">
                    <li style={{ display: "none" }}>
                      <a className="twitter" href="/">
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Social icon">
                            <path
                              d="M7.55016 22.2617C16.6045 22.2617 21.5583 14.7584 21.5583 8.25358C21.5583 8.04264 21.5536 7.82702 21.5442 7.61608C22.5079 6.91918 23.3395 6.05597 24 5.06702C23.1025 5.46632 22.1496 5.7271 21.1739 5.84045C22.2013 5.22463 22.9705 4.25719 23.3391 3.11749C22.3726 3.69028 21.3156 4.09433 20.2134 4.31233C19.4708 3.52328 18.489 3.00084 17.4197 2.82577C16.3504 2.6507 15.2532 2.83277 14.2977 3.34382C13.3423 3.85486 12.5818 4.66642 12.1338 5.65303C11.6859 6.63964 11.5754 7.74633 11.8195 8.80202C9.86249 8.70381 7.94794 8.19542 6.19998 7.30982C4.45203 6.42421 2.90969 5.18116 1.67297 3.66124C1.0444 4.74496 0.852057 6.02736 1.13503 7.24781C1.418 8.46826 2.15506 9.53517 3.19641 10.2317C2.41463 10.2069 1.64998 9.9964 0.965625 9.61764V9.67858C0.964925 10.8159 1.3581 11.9183 2.07831 12.7985C2.79852 13.6787 3.80132 14.2823 4.91625 14.5067C4.19206 14.7048 3.43198 14.7337 2.69484 14.5911C3.00945 15.5692 3.62157 16.4246 4.44577 17.0381C5.26997 17.6515 6.26512 17.9923 7.29234 18.013C5.54842 19.3828 3.39417 20.1258 1.17656 20.1223C0.783287 20.1217 0.390399 20.0976 0 20.0501C2.25286 21.4955 4.87353 22.2631 7.55016 22.2617Z"
                              fill="#98A2B3"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        className="linkedin"
                        href="https://www.linkedin.com/in/saleem-moosa?utm_source=share&utm_campaign=share_via&utm_content=profile"
                        target="_blank"
                      >
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Group">
                            <path
                              d="M22.2234 0.511719H1.77187C0.792187 0.511719 0 1.28516 0 2.24141V22.7773C0 23.7336 0.792187 24.5117 1.77187 24.5117H22.2234C23.2031 24.5117 24 23.7336 24 22.782V2.24141C24 1.28516 23.2031 0.511719 22.2234 0.511719ZM7.12031 20.9633H3.55781V9.50703H7.12031V20.9633ZM5.33906 7.94609C4.19531 7.94609 3.27188 7.02266 3.27188 5.88359C3.27188 4.74453 4.19531 3.82109 5.33906 3.82109C6.47813 3.82109 7.40156 4.74453 7.40156 5.88359C7.40156 7.01797 6.47813 7.94609 5.33906 7.94609ZM20.4516 20.9633H16.8937V15.3945C16.8937 14.068 16.8703 12.357 15.0422 12.357C13.1906 12.357 12.9094 13.8055 12.9094 15.3008V20.9633H9.35625V9.50703H12.7687V11.0727H12.8156C13.2891 10.1727 14.4516 9.22109 16.1813 9.22109C19.7859 9.22109 20.4516 11.593 20.4516 14.6773V20.9633Z"
                              fill="#98A2B3"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li style={{ display: "none" }}>
                      <a className="dribble" href="/">
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Social icon">
                            <path
                              clipRule="evenodd"
                              d="M12 0.511719C5.37527 0.511719 0 5.88699 0 12.5117C0 19.1365 5.37527 24.5117 12 24.5117C18.6117 24.5117 24 19.1365 24 12.5117C24 5.88699 18.6117 0.511719 12 0.511719ZM19.9262 6.04317C21.3579 7.78721 22.217 10.0128 22.243 12.4206C21.9046 12.3556 18.5206 11.6657 15.1106 12.0952C15.0325 11.926 14.9675 11.7438 14.8894 11.5616C14.6811 11.0671 14.4469 10.5595 14.2126 10.0779C17.9869 8.54212 19.705 6.32951 19.9262 6.04317ZM12 2.28179C14.603 2.28179 16.9848 3.25793 18.7939 4.85879C18.6117 5.1191 17.0629 7.18851 13.4186 8.55509C11.7397 5.4705 9.87855 2.94556 9.5922 2.5551C10.3601 2.37289 11.1671 2.28179 12 2.28179ZM7.63995 3.24491C7.91325 3.60933 9.73538 6.14729 11.4404 9.16679C6.65076 10.4423 2.42083 10.4163 1.96529 10.4163C2.62907 7.24057 4.77657 4.59848 7.63995 3.24491ZM1.74404 12.5248C1.74404 12.4206 1.74404 12.3165 1.74404 12.2124C2.18655 12.2254 7.15835 12.2905 12.2733 10.7547C12.5727 11.3273 12.846 11.913 13.1063 12.4987C12.9761 12.5377 12.8329 12.5768 12.7028 12.6158C7.41865 14.3208 4.60738 18.9802 4.3731 19.3707C2.7462 17.5616 1.74404 15.1538 1.74404 12.5248ZM12 22.7677C9.6312 22.7677 7.44469 21.9607 5.71367 20.6071C5.89588 20.2297 7.97827 16.2211 13.757 14.2037C13.783 14.1907 13.7961 14.1907 13.8221 14.1777C15.2668 17.913 15.8525 21.0496 16.0087 21.9477C14.7722 22.4813 13.4186 22.7677 12 22.7677ZM17.7136 21.0106C17.6096 20.3859 17.0629 17.3924 15.7223 13.7091C18.9371 13.2015 21.7484 14.0345 22.0998 14.1517C21.6573 17.002 20.0173 19.4618 17.7136 21.0106Z"
                              fill="#98A2B3"
                              fillRule="evenodd"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="team card"
                style={{
                  display: "inline-block",
                  width: "100%",
                }}
                tabIndex="-1"
              >
                <div className="img">
                  <img src="/img/Khurram.jpeg" />
                </div>
                <div className="card-content">
                  <h4>Khurram Shroff</h4>
                  <ul className="list-inline">
                    <li style={{ display: "none" }}>
                      <a className="twitter" href="/">
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Social icon">
                            <path
                              d="M7.55016 22.2617C16.6045 22.2617 21.5583 14.7584 21.5583 8.25358C21.5583 8.04264 21.5536 7.82702 21.5442 7.61608C22.5079 6.91918 23.3395 6.05597 24 5.06702C23.1025 5.46632 22.1496 5.7271 21.1739 5.84045C22.2013 5.22463 22.9705 4.25719 23.3391 3.11749C22.3726 3.69028 21.3156 4.09433 20.2134 4.31233C19.4708 3.52328 18.489 3.00084 17.4197 2.82577C16.3504 2.6507 15.2532 2.83277 14.2977 3.34382C13.3423 3.85486 12.5818 4.66642 12.1338 5.65303C11.6859 6.63964 11.5754 7.74633 11.8195 8.80202C9.86249 8.70381 7.94794 8.19542 6.19998 7.30982C4.45203 6.42421 2.90969 5.18116 1.67297 3.66124C1.0444 4.74496 0.852057 6.02736 1.13503 7.24781C1.418 8.46826 2.15506 9.53517 3.19641 10.2317C2.41463 10.2069 1.64998 9.9964 0.965625 9.61764V9.67858C0.964925 10.8159 1.3581 11.9183 2.07831 12.7985C2.79852 13.6787 3.80132 14.2823 4.91625 14.5067C4.19206 14.7048 3.43198 14.7337 2.69484 14.5911C3.00945 15.5692 3.62157 16.4246 4.44577 17.0381C5.26997 17.6515 6.26512 17.9923 7.29234 18.013C5.54842 19.3828 3.39417 20.1258 1.17656 20.1223C0.783287 20.1217 0.390399 20.0976 0 20.0501C2.25286 21.4955 4.87353 22.2631 7.55016 22.2617Z"
                              fill="#98A2B3"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        className="linkedin"
                        href="https://www.linkedin.com/in/khurramshroff?utm_source=share&utm_campaign=share_via&utm_content=profile"
                        target="_blank"
                      >
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Group">
                            <path
                              d="M22.2234 0.511719H1.77187C0.792187 0.511719 0 1.28516 0 2.24141V22.7773C0 23.7336 0.792187 24.5117 1.77187 24.5117H22.2234C23.2031 24.5117 24 23.7336 24 22.782V2.24141C24 1.28516 23.2031 0.511719 22.2234 0.511719ZM7.12031 20.9633H3.55781V9.50703H7.12031V20.9633ZM5.33906 7.94609C4.19531 7.94609 3.27188 7.02266 3.27188 5.88359C3.27188 4.74453 4.19531 3.82109 5.33906 3.82109C6.47813 3.82109 7.40156 4.74453 7.40156 5.88359C7.40156 7.01797 6.47813 7.94609 5.33906 7.94609ZM20.4516 20.9633H16.8937V15.3945C16.8937 14.068 16.8703 12.357 15.0422 12.357C13.1906 12.357 12.9094 13.8055 12.9094 15.3008V20.9633H9.35625V9.50703H12.7687V11.0727H12.8156C13.2891 10.1727 14.4516 9.22109 16.1813 9.22109C19.7859 9.22109 20.4516 11.593 20.4516 14.6773V20.9633Z"
                              fill="#98A2B3"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li style={{ display: "none" }}>
                      <a className="dribble" href="/">
                        <svg
                          fill="none"
                          height="25"
                          viewBox="0 0 24 25"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Social icon">
                            <path
                              clipRule="evenodd"
                              d="M12 0.511719C5.37527 0.511719 0 5.88699 0 12.5117C0 19.1365 5.37527 24.5117 12 24.5117C18.6117 24.5117 24 19.1365 24 12.5117C24 5.88699 18.6117 0.511719 12 0.511719ZM19.9262 6.04317C21.3579 7.78721 22.217 10.0128 22.243 12.4206C21.9046 12.3556 18.5206 11.6657 15.1106 12.0952C15.0325 11.926 14.9675 11.7438 14.8894 11.5616C14.6811 11.0671 14.4469 10.5595 14.2126 10.0779C17.9869 8.54212 19.705 6.32951 19.9262 6.04317ZM12 2.28179C14.603 2.28179 16.9848 3.25793 18.7939 4.85879C18.6117 5.1191 17.0629 7.18851 13.4186 8.55509C11.7397 5.4705 9.87855 2.94556 9.5922 2.5551C10.3601 2.37289 11.1671 2.28179 12 2.28179ZM7.63995 3.24491C7.91325 3.60933 9.73538 6.14729 11.4404 9.16679C6.65076 10.4423 2.42083 10.4163 1.96529 10.4163C2.62907 7.24057 4.77657 4.59848 7.63995 3.24491ZM1.74404 12.5248C1.74404 12.4206 1.74404 12.3165 1.74404 12.2124C2.18655 12.2254 7.15835 12.2905 12.2733 10.7547C12.5727 11.3273 12.846 11.913 13.1063 12.4987C12.9761 12.5377 12.8329 12.5768 12.7028 12.6158C7.41865 14.3208 4.60738 18.9802 4.3731 19.3707C2.7462 17.5616 1.74404 15.1538 1.74404 12.5248ZM12 22.7677C9.6312 22.7677 7.44469 21.9607 5.71367 20.6071C5.89588 20.2297 7.97827 16.2211 13.757 14.2037C13.783 14.1907 13.7961 14.1907 13.8221 14.1777C15.2668 17.913 15.8525 21.0496 16.0087 21.9477C14.7722 22.4813 13.4186 22.7677 12 22.7677ZM17.7136 21.0106C17.6096 20.3859 17.0629 17.3924 15.7223 13.7091C18.9371 13.2015 21.7484 14.0345 22.0998 14.1517C21.6573 17.002 20.0173 19.4618 17.7136 21.0106Z"
                              fill="#98A2B3"
                              fillRule="evenodd"
                              id="Vector"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
