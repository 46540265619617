import { Link } from "react-router-dom";
const About = () => {
  return (
    <>
      <section className="About" id="about">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-12 col-sm-12">
              <div className="left-content">
                <h4>
                  <img src="/img/aboutsubtract.svg" />
                  imining
                </h4>
                <h1>
                  WE ACQUIRE, BUILD, AND MANAGE FUTURE TECHNOLOGY COMPANIES THAT
                  CAN SCALE GLOBALLY.
                </h1>
              </div>
            </div>
            <div className="col-xl-7 col-lg-12 col-sm-12">
              <div className="right-content">
                <div className="heading">
                  <div>
                    <h4>
                    TSXv & Borse Frankfurt <br /> Listed Company
                    </h4>
                  </div>
                  <div className="text-end">
                    {/* <img src="/img/imaewithtext.svg" /> */}
                    <img src="/img/listingone.png" />
                    <img src="/img/listingtwo.png" />
                  </div>
                </div>
                <p>
                  iMining Technologies Inc. is a publicly listed technology
                  company which together with its subsidiaries acquires, builds,
                  and manages future technology companies that can scale
                  globally. Our industry specific technology businesses focuses
                  on harnessing Artificial Intelligence to provide innovative
                  and hyper-scalable solutions. We’re establishing a broad
                  portfolio of Ai businesses across all sectors to provide our
                  customers and shareholders with exceptional returns.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
