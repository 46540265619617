export default function WhoWeAre() {
  return (
    <>
      <section className="WhoWeAre">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-3">
              <div className="heading">
                <h4>Who</h4>
                <p>are we</p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-9">
              <div className="desc">
                <p>
                  iMining Technologies Inc. is a publicly listed technology
                  company which together with its subsidiaries acquires, builds,
                  and manages future technology companies that can scale
                  globally.
                </p>
                <br />
                <p>
                  Our industry specific technology businesses focuses on
                  harnessing future technologies to provide innovative and
                  hyper-scalable solutions. We’re establishing a broad portfolio
                  of technology businesses across multiple sectors to provide
                  our clients and shareholders with exceptional returns.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
