import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Header from './component/layout/Header';
import Footer from "./component/layout/Footer";
import Home from './component/view/home';
import About from "./component/view/about";
import Company from "./component/view/company";
import BoardDirectors from "./component/view/directors";
import Investor from "./component/view/investor";
import Stock from "./component/view/stock";
import Financial from "./component/view/financial";
import InterviewsPage from "./component/view/interviews";
import PressList from "./component/view/press/list/list";
import PressDetail from "./component/view/press/detail/detail";
import Contact from "./component/view/contact/Contact";
import Disclaimer from "./component/view/Privacy/Disclaimer";
import Privacy from "./component/view/Privacy/Privacy";
import Terms from "./component/view/Privacy/Terms";
import ComingSoon from "./common/ComingSoon";
import { SkeletonTheme } from 'react-loading-skeleton';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import 'react-loading-skeleton/dist/skeleton.css';


function App() {
  return (
    <>
      <SkeletonTheme baseColor="#fff" highlightColor="#74797c66">
      <Router>
        <div className="wrapper">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/our-company" element={<Company />} />
            <Route path="/directors" element={<BoardDirectors />} />
            <Route path="/investor" element={<Investor />} />
            <Route path="/stock" element={<Stock />} />
            <Route path="/financial" element={<Financial />} />
            <Route path="/interviews" element={<InterviewsPage />} />
            <Route path="/press-release" element={<PressList />} />
            <Route path="/press-release-detail/:id" element={<PressDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-and-condition" element={<Terms />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            {/* <Route path="/press-release-detail" element={<PressDetail />} /> */}
            <Route path="*" element={<h2>404 Not Found</h2>} />
          </Routes>
          <Footer />
        </div>
      </Router>
      </SkeletonTheme>
    </>
  );
}

export default App;
