import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import data from "./data.json";
import PDFViewer from "../../../../common/PDFViewer";
import Skeleton from "react-loading-skeleton";

const token = process.env.REACT_APP_PRESS_API_TOKEN;
const url = process.env.REACT_APP_PRESS_API_ENDPOINT;

function PressDetail() {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${url}/api/iminings/${id}?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data.data?.attributes || {});
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  return (
    <>
      <section className="PressList">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 col-lg-8 col-md-12 col-sm-12">
              <div className="">
                <div>
                  <h4
                    className="mb-5"
                    style={{
                      fontFamily: "Inter",
                      fontSize: "32px",
                      fontWeight: "700",
                      color: "#000",
                    }}
                  >
                    {isLoading ? <Skeleton count={1} /> : data.title}
                  </h4>
                  <div>
                    {isLoading ? <Skeleton height={'200px'} count={1} /> : data?.media?.data?.attributes?.url ? (
                      <img
                        src={`${url}${data.media.data.attributes.url}`}
                        className="w-100"
                      />
                    ) : null}
                  </div>
                  <p
                    className="mb-5 mt-5"
                    style={{
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    {/* {data.description} */}
                    {isLoading ? <Skeleton count={10} /> : data.description?.map((paragraph, index) => (
                      <p key={index}>{paragraph.children[0].text}</p>
                    ))}
                  </p>
                  <div>
                    {isLoading ? <Skeleton count={10} /> : data?.document?.data?.map(({attributes}) => {
                      return(
                      <PDFViewer
                        pdfUrl={attributes.url}
                        name={attributes.name}
                      />
                    )})}
                  </div>
                  <label style={{ color: "#9770ff", marginTop: "10px" }}>
                    Posted Date:{" "}
                    {moment(data.publishedDate).format("MMMM DD, YYYY")}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default PressDetail;
