export default function Hero() {
  return (
    <>
      <section className="AboutHero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div class="text-center">
                <div class="Hero-Content gap-24">
                  <div class="Frame-Route">
                    <h4>
                      <img src="/img/aboutsubtract.svg" />
                      OVERVIEW
                    </h4>
                  </div>
                  <div class="Content">
                    <div>
                      <h4>We Acquire, build, and manage future technology companies that can scale globally.</h4>
                    </div>
                    {/* <div>
                      <p>
                        AI is a powerful tool that demands responsibility. We
                        prioritize human-centeredness, starting with academic
                        research and progressing to responsible AI systems. Our
                        goal is to make AI accessible across various sectors,
                        from enterprises to passion projects like oncology and
                        assistive technologies for children with autism.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
