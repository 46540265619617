import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
export default function ComingSoon() {
  const [time, setTime] = useState(getCurrentTime());

  // Function to get current time
  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const twelveHourFormat = hours % 12 || 12;
    return `${twelveHourFormat}:${minutes}:${seconds} ${ampm}`;
  }

  // useEffect(() => {
  //   // Update the time every second
  //   const intervalID = setInterval(() => {
  //     setTime(getCurrentTime());
  //   }, 1000);

  //   // Cleanup function to clear interval on unmount
  //   return () => clearInterval(intervalID);
  // }, []);
  return (
    <>
     

      <section className="Hero">
        <div className="border-frame">
          <img src="/img/heroframe.svg" />
        </div>
        <div className="hero-bottom-content">
          <div className="user">
            <ul className="list-inline">
              <li>
                <Link
                  to="https://ca.linkedin.com/company/imininginc"
                  target="_blank"
                >
                  <img src="/img/linkedin.svg" />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/iMiningInc" target="_blank">
                  <img src="/img/twitter.svg" />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/channel/UCCmyxR_woG1f_qXAWPn5dKQ"
                  target="_blank"
                >
                  <img src="/img/youtube.svg" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="partner">
            <div className="content">
              <Link to="">Explore Collaborating Opportunities</Link>
              <Link className="mail" to="">
                <img src="/img/mail.svg" />
                info@imining.com
              </Link>
            </div>
            <div className="img">
              <img style={{ cursor: "pointer" }} src="/img/arrowcircle.svg" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero-center-content">
                <h1>
                 Coming soon
                </h1>
                <h4 style={{ color: 'white' }}>
                 This page is under development and will be live soon
                </h4>
                {/* <div style={{color:"#fff", border:"1px solid #fff", borderRadius:"10px", fontSize:"24px"}} className="py-3 px-4 d-inline" id="clock">{time}</div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
