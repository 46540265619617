export default function Hero() {
  return (
    <>
      <section className="AboutHero InvestorHero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div class="text-center">
                <div class="Hero-Content gap-24">
                  <div class="Frame-Route">
                    <h4>
                      <img src="/img/aboutsubtract.svg" />
                      investors
                    </h4>
                  </div>
                  <div class="Content">
                    <div>
                      <h4>Financial Reports</h4>
                    </div>
                    <div>
                      <p>
                        Our Financial Reports page provides comprehensive and
                        transparent access to our company's financial
                        performance and strategic outlook. Here, stakeholders
                        can find detailed insights into our fiscal health,
                        operational efficiency, and long-term growth prospects,
                        underscoring our commitment to accountability and
                        transparency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
