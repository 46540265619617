import { Link } from "react-router-dom";
export default function CompanyList() {
  return (
    <>
      <section className="CompanyList">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="companies">
                <div className="heading">
                  <h4>AiMining Technologies Inc.</h4>
                </div>
                <div className="company-detail">
                  <div className="banner">
                    <img src="/img/company1.png" />
                  </div>
                  <div className="content">
                    <p>
                      AiMining Technologies Inc is recognized as a Nvidia
                      Inception Partner, as well as being chosen for the
                      Microsoft start-up Founders Hub, stands as a pioneering
                      force in Artificial Intelligence (AI) advancement.
                    </p>
                  </div>
                  <div className="link">
                    <Link
                      style={{
                        border: "1px solid #000",
                        fontFamily: "Inter",
                        padding: "12px 30px",
                        textDecoration: "none",
                        width: "fit-content",
                      }}
                      to="/contact"
                      className="press-btn send-mess"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="companies">
                <div className="heading">
                  <h4>Metaverse Advisory Group Inc.</h4>
                </div>
                <div className="company-detail">
                  <div className="banner">
                    <img src="/img/company2.png" />
                  </div>
                  <div className="content">
                    <p>
                      Metaverse Advisory Group is at the forefront of the
                      digital frontier, offering expert guidance and innovative
                      solutions in the rapidly evolving landscape of the
                      metaverse. We specialize in helping businesses navigate,
                      strategize, and thrive in virtual environments, ensuring
                      they harness the full potential of this transformative
                      technology.
                    </p>
                  </div>
                  <div className="link">
                    <Link
                      style={{
                        border: "1px solid #000",
                        fontFamily: "Inter",
                        padding: "12px 30px",
                        textDecoration: "none",
                        width: "fit-content",
                      }}
                      to="/contact"
                      className="press-btn send-mess"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="companies">
                <div className="heading">
                  <h4>BitBit Financial Inc.</h4>
                </div>
                <div className="company-detail">
                  <div className="banner">
                    <img src="/img/company3.png" />
                  </div>
                  <div className="content">
                    <p>
                      BitBit Financial is a premier over-the-counter (OTC) desk
                      for cryptocurrency trading, offering a secure, efficient,
                      and personalized trading experience. Catering to corporate
                      customers, high-net-worth individuals, and crypto
                      enthusiasts, we provide a seamless platform for
                      large-volume trades with competitive pricing.
                    </p>
                  </div>
                  <div className="link">
                    <Link
                      style={{
                        border: "1px solid #000",
                        fontFamily: "Inter",
                        padding: "12px 30px",
                        textDecoration: "none",
                        width: "fit-content",
                      }}
                      to="/contact"
                      className="press-btn send-mess"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="companies">
                <div className="heading">
                  <h4>Caneth Staking Services Inc.</h4>
                </div>
                <div className="company-detail">
                  <div className="banner">
                    <img src="/img/company4.png" />
                  </div>
                  <div className="content">
                    <p>
                      CanEth Staking Services is a leading provider of staking
                      solution technology for Ethereum 2.0, offering secure,
                      reliable, and user-friendly services for individuals and
                      institutions looking to maximize their returns from
                      staking their ETH. With a commitment to excellence CanEth
                      simplifies the staking process, enabling Ethereum
                      network's transition to proof-of-stake (P.O.S)
                      effortlessly.
                    </p>
                  </div>
                  <div className="link">
                    <Link
                      style={{
                        border: "1px solid #000",
                        fontFamily: "Inter",
                        padding: "12px 30px",
                        textDecoration: "none",
                        width: "fit-content",
                      }}
                      to="/contact"
                      className="press-btn send-mess"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="CompanyList">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="companies">
                <div className="heading">
                  <h4>AiMining Technologies Inc.</h4>
                </div>
                <div className="company-detail">
                  <div className="banner">
                    <img src="/img/company1.png" />
                  </div>
                  <div className="content">
                    <ul className="list-unstyled">
                      <li>Agri-Food</li>
                      <li>Bio Sciences</li>
                      <li>Cultural Collections Management</li>
                      <li>Education</li>
                    </ul>
                  </div>
                  <div className="content">
                    <ul className="list-unstyled">
                      <li>Communication & Media</li>
                      <li>Drinks</li>
                      <li>Financial Services</li>
                      <li>Library Management</li>
                    </ul>
                  </div>
                  <div className="link">
                    <Link to="">Visit Site &gt;</Link>
                  </div>
                </div>
              </div>
              <div className="companies">
                <div className="heading">
                  <h4>Metaverse Advisory Group</h4>
                </div>
                <div className="company-detail">
                  <div className="banner">
                    <img src="/img/company2.png" />
                  </div>
                  <div className="content">
                    <ul className="list-unstyled">
                      <li>Agri-Food</li>
                      <li>Bio Sciences</li>
                      <li>Cultural Collections Management</li>
                      <li>Education</li>
                    </ul>
                  </div>
                  <div className="content">
                    <ul className="list-unstyled">
                      <li>Communication & Media</li>
                      <li>Drinks</li>
                      <li>Financial Services</li>
                      <li>Library Management</li>
                    </ul>
                  </div>
                  <div className="link">
                    <Link to="">Visit Site &gt;</Link>
                  </div>
                </div>
              </div>
              <div className="companies">
                <div className="heading">
                  <h4>BitBit Financial Inc.</h4>
                </div>
                <div className="company-detail">
                  <div className="banner">
                    <img src="/img/company3.png" />
                  </div>
                  <div className="content">
                    <ul className="list-unstyled">
                      <li>Agri-Food</li>
                      <li>Bio Sciences</li>
                      <li>Cultural Collections Management</li>
                      <li>Education</li>
                    </ul>
                  </div>
                  <div className="content">
                    <ul className="list-unstyled">
                      <li>Communication & Media</li>
                      <li>Drinks</li>
                      <li>Financial Services</li>
                      <li>Library Management</li>
                    </ul>
                  </div>
                  <div className="link">
                    <Link to="">Visit Site &gt;</Link>
                  </div>
                </div>
              </div>
              <div className="companies">
                <div className="heading">
                  <h4>CanETH Staking Company</h4>
                </div>
                <div className="company-detail">
                  <div className="banner">
                    <img src="/img/company4.png" />
                  </div>
                  <div className="content">
                    <ul className="list-unstyled">
                      <li>Agri-Food</li>
                      <li>Bio Sciences</li>
                      <li>Cultural Collections Management</li>
                      <li>Education</li>
                    </ul>
                  </div>
                  <div className="content">
                    <ul className="list-unstyled">
                      <li>Communication & Media</li>
                      <li>Drinks</li>
                      <li>Financial Services</li>
                      <li>Library Management</li>
                    </ul>
                  </div>
                  <div className="link">
                    <Link to="">Visit Site &gt;</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
