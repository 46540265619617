import Hero from "./section/Hero";
import Directors from "../about/section/Directors";
import Committees from "./section/Committees";
const BoardDirectors = () => {
    return (
        <>
            <Hero />
            <Directors />
            <Committees />
        </>
    )
}

export default BoardDirectors;