import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import React from "react";
import Slider from "react-slick";



import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
// import PressReleaseTile from "../PressReleaseTile/PressReleaseTile";
import PressReleaseTile from "../../press/PressReleaseTile/PressReleaseTile";
const token = process.env.REACT_APP_PRESS_API_TOKEN;
const url = process.env.REACT_APP_PRESS_API_ENDPOINT;




export default function Reports() {


  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/iminings?filters[category][$eq]=FinancialReport&populate=*`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        setData(response.data.data.reverse());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [token]);


  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  return (
    <>
      <section className="Press Press-List Reports" id="press">
        <div className="container">
          
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="horizontimeline">
                <ul className="list-inline">
                  {isLoading
                    ? [0, 1, 2, 3].map((_) => <PressReleaseTile />)
                    : data.map((press, index) => (
                        <PressReleaseTile key={press.id} press={press} />
                      ))}
                </ul>
              </div>

              
            </div>
          </div>
        </div>
      </section>

      {/* <section className="Reports">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Tabs className="customtab">
                <TabList>
                  <Tab>All</Tab>
                  <Tab>Quarter Report</Tab>
                  <Tab>Annual Report</Tab>
                </TabList>

                <TabPanel>
                  <h2>
                    <Slider {...settings}>
                      <div className="reportlist">
                        <label>April 30, 2024</label>
                        <h4>2022 Second Quarter Report</h4>
                        <p>
                          Kriti Srikanth Joins AiMining Technologies, a
                          Subsidiary of iMining, as an Advisory Board Member to
                          Focus on the Healthcare Sector
                        </p>
                        <p></p>
                      </div>
                      <div className="reportlist">
                        <label>April 30, 2024</label>
                        <h4>2022 Second Quarter Report</h4>
                        <p>
                          Kriti Srikanth Joins AiMining Technologies, a
                          Subsidiary of iMining, as an Advisory Board Member to
                          Focus on the Healthcare Sector
                        </p>
                        <p></p>
                      </div>
                      <div className="reportlist">
                        <label>April 30, 2024</label>
                        <h4>2022 Second Quarter Report</h4>
                        <p>
                          Kriti Srikanth Joins AiMining Technologies, a
                          Subsidiary of iMining, as an Advisory Board Member to
                          Focus on the Healthcare Sector
                        </p>
                        <p></p>
                      </div>
                      <div className="reportlist">
                        <label>April 30, 2024</label>
                        <h4>2022 Second Quarter Report</h4>
                        <p>
                          Kriti Srikanth Joins AiMining Technologies, a
                          Subsidiary of iMining, as an Advisory Board Member to
                          Focus on the Healthcare Sector
                        </p>
                        <p></p>
                      </div>
                      <div className="reportlist">
                        <label>April 30, 2024</label>
                        <h4>2022 Second Quarter Report</h4>
                        <p>
                          Kriti Srikanth Joins AiMining Technologies, a
                          Subsidiary of iMining, as an Advisory Board Member to
                          Focus on the Healthcare Sector
                        </p>
                        <p></p>
                      </div>
                      <div className="reportlist">
                        <label>April 30, 2024</label>
                        <h4>2022 Second Quarter Report</h4>
                        <p>
                          Kriti Srikanth Joins AiMining Technologies, a
                          Subsidiary of iMining, as an Advisory Board Member to
                          Focus on the Healthcare Sector
                        </p>
                        <p></p>
                      </div>
                    </Slider>
                  </h2>
                </TabPanel>
                <TabPanel></TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
