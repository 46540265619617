import { Link } from "react-router-dom";
export default function Disclaimer() {
  return (
    <>
      <section className="PrivacyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content">
                <p>
                  This is the official website of iMining Technologies Inc.
                  (“iMining”, “iMining Technologies” or the “Company”). The
                  content of this website is owned by iMining and is protected
                  by copyright law, as a collective work and compilation, both
                  in Canada and abroad.
                </p>
                <p>
                  This website is designed for information purposes only.
                  Although iMining has taken considerable care in preparing and
                  maintaining the information and materials contained in this
                  website, the information is taken from sources believed to be
                  reliable, and the information is believed to be accurate at
                  the time it is posted to the website. However, there is no way
                  to ensure that the information is accurate at any particular
                  moment in time. Material on the site may still contain
                  technical or other inaccuracies, omissions, or typographical
                  errors, for which iMining assumes no responsibility for its
                  completeness, accuracy, currency, reliability or validity of
                  any claims, statements or information on this site.
                </p>
                <p>
                  In using this Website, you agree that iMining, its directors,
                  officers, subsidiaries, affiliates, contractors, agents,
                  employees, licensors, and/or third-party content providers
                  shall not be liable for any damages whatsoever (including
                  indirect, incidental, special, punitive or consequential
                  damages and loss of profits, opportunities or information)
                  arising from (a) your use of or reliance on information
                  contained on this website; (b) any inaccuracy or omission in
                  such information or failure to keep the information current;
                  (c) use of any third party websites linked or referred to in
                  this website; (d) any delays, inaccuracies or errors in, or in
                  the transmission of, any stock price quotes or historical
                  price data; (e) any Internet software used in connection with
                  this Website or computer viruses or other destructive programs
                  encountered as a result of using this website; and (f) any
                  other matter connected with the website; even if iMining
                  Technologies is made aware of the possibility of such claims,
                  damages or losses.
                </p>
                <p>
                  The information contained on this Website in no way should be
                  construed or interpreted as, or as part of a a prospectus,
                  offering memorandum, advertisement, or solicitation and does
                  not constitute or form part of, and should not be construed
                  as, an offer or invitation to sell or any solicitation of any
                  offer to purchase or subscribe for any securities of the
                  Company in Canada, the United States or any other
                  jurisdiction. Neither the information on this Website, nor any
                  part of it nor anything contained or referred to in it, nor
                  the fact of its distribution, should form the basis of or be
                  relied on in connection with or act as an inducement in
                  relation to a decision to purchase or subscribe for or enter
                  into any contract or make any other commitment whatsoever in
                  relation to any securities of the Company. No representation
                  or warranty, expressed or implied, is given by or on behalf of
                  the Company, its directors and affiliates or any other person
                  as to the accuracy or completeness of the information or
                  opinions contained in this website; and no liability
                  whatsoever is accepted by the Company, its directors and
                  affiliates or any other person for any loss howsoever arising,
                  directly or indirectly, from any use of such information or
                  opinions or otherwise arising in connection therewith. No
                  investment advice is offered or deemed to be offered on the
                  website, and any prospective investor should consult with his
                  own legal, investment, accounting and tax advisors for
                  determination of, among other things, suitability of investing
                  in securities of the Company.
                </p>
                <p>
                  Purchasing securities of the Company should be considered a
                  very risky investment as the securities are speculative in
                  nature and are appropriate only for investors who are prepared
                  to have their money invested for a long period of time and
                  have the capacity to absorb a loss of some or all of their
                  investment. Specifically, the Company is not currently mining
                  for any Cryptocurrencies, is not earning any revenue or making
                  any profit, and is essentially inactive; and there is no
                  assurance the Company will be successful in ever resuming
                  crypto mining operations, earning any revenue or making any
                  profit.
                </p>
                <p>
                  No reliance may be placed for any purpose whatsoever on the
                  information or opinions contained in this website or on its
                  completeness, accuracy or fairness. Readers should not treat
                  the contents of this website as advice relating to legal,
                  taxation or investment matters, and must make their own
                  assessments concerning these and other consequences of the
                  various investments, including the merits of investing and the
                  risks. Readers are advised to consult their own personal
                  legal, tax and accounting advisors and to conduct their own
                  due diligence and agree to be bound by the limitations of this
                  disclaimer.
                </p>
                <h4>FORWARD-LOOKING INFORMATION</h4>
                <p>
                  Except for the statements of historical fact, this website
                  contains “forward-looking information” (within the meaning of
                  the applicable Canadian securities legislation) that is based
                  on expectations, estimates and projections of iMining. The
                  information on this website about the completion of any
                  transaction, goals, objectives, any estimate of potential
                  earnings, the performance of any obligations of parties to
                  transaction agreements, and other forward-looking information,
                  includes but is not limited to information concerning the
                  intentions, plans, and future actions of the parties to the
                  transactions described herein and the terms thereon.
                </p>
                <p>
                  Factors that could cause actual results to differ materially
                  from those described in such forward-looking information
                  include, but are not limited to risks related to regulatory
                  restrictions, the Cryptocurrency markets generally, our
                  ability to successfully mine Cryptocurrency, and other risks
                  as more fully set out in our Filing Statement’s disclosures
                  filed at www.sedar.com.
                </p>
                <p>
                  The forward-looking information on this website reflects the
                  current expectations, assumptions and/or beliefs of the
                  Company based on information currently available to the
                  Company. In connection with the forward-looking information
                  contained on this website, the Company has made assumptions,
                  including, but not limited to assumptions about iMining’s
                  ability to complete any contemplated transactions, about
                  historical prices of Cryptocurrencies and the ability to
                  successfully mine Cryptocurrencies consistent with historical
                  prices, and that there will be no regulation or law that will
                  prevent us from operating its business. The Company has also
                  assumed that no significant events occur outside of the
                  Company’s normal course of business. Although the Company
                  believes that the assumptions inherent in the forward-looking
                  information are reasonable, forward-looking information is not
                  a guarantee of future performance and accordingly undue
                  reliance should not be put on such information due to the
                  inherent uncertainty therein.
                </p>
                <p>
                  Any forward-looking information speaks only as of the date on
                  which it is made and, except as may be required by applicable
                  securities laws, the Company disclaims any intent or
                  obligation to update any forward-looking information, whether
                  as a result of new information, future events or results or
                  otherwise.
                </p>
                <p>
                  iMining reserves the right to modify this Disclaimer without
                  prior notice by posting modifications to this website.
                </p>
                <h4>TRADEMARK NOTICE</h4>
                <p>
                  iMining Technologies is a trademark of iMining Technologies
                  Inc. All other product, brand and Company names and logos used
                  or mentioned on this website may be the trademarks or
                  registered trademarks of their respective owners. Any use of
                  any trademarks appearing on this website without the express
                  written consent of the owner of the trademark is strictly
                  prohibited.
                </p>
                <h4>PRIVACY STATEMENT</h4>
                <p>
                  iMining is concerned for and respects the privacy of each
                  visitor to this web site. When you visit certain areas of this
                  web site you may be asked for personal information such as
                  your name, address, telephone number and email address. The
                  intended use of such information is specified at the site
                  where the information is collected. No personal information
                  about you shall be collected without your consent.
                </p>
                <p>
                  iMining also collects aggregated or non-personal information
                  to track data such as the total number of visits to this web
                  site or the number of visitors to each web page. This
                  information shall remain in aggregated form and may be used to
                  understand how visitors use this web site.
                </p>
                <p>
                  Please click the following link for more information about
                  iMining privacy policy:
                  <Link to="/privacy-policy">
                    https://www.imining.com/privacy-policy/
                  </Link>
                </p>
                <h4>THIRD PARTY LINKS</h4>
                <p>
                  iMining has provided links from this web site to other web
                  sites which are arms-length to the Company. The viewer should
                  be aware that in linking to these outside web sites, they are
                  leaving the iMining web site and that iMining Technologies is
                  not responsible for the content of any other site.
                </p>
                <p>For more information contact us at legal@imining.com.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
