import Hero from "./section/Hero";
import About from "./section/About";
import Research from "./section/Research";
import Partner from "./section/Partner";
import Product from "./section/Product";
import Mission from "./section/Mission";
import Team from "./section/Team";
import Press from "./section/Press";
const Home = () => {
    return (
        <>
            <Hero />
             <About />
            {/* <Research /> */}
            {/* <Partner /> */}
            {/* <Product /> */}
            <Mission />
            <Team />
           <Press />
        </>
    )
}

export default Home;