import * as React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

const Footer = () => {

  const { pathname } = useLocation();

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [pathname])

  return (
    <>
    <div style={{backgroundColor:"#000"}}>
      <footer className="custom-footer" id="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-lsm-12">
              <div className="left-content">
                <h4>
                  Let’s talk
                  <img src="/img/arrow.svg" />
                </h4>
              </div>
              <div className="quicklinks">
                <ul className="list-inline">
                  <li>
                    <Link to="/contact">
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to="/disclaimer">
                      Disclaimer
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-condition">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                 
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-lsm-12">
              <div className="right-content">
                <div>
                  <h4>
                    Head Office
                  </h4>
                  <p>
                    580 Hornby Street, Suite 750 Vancouver, British Columbia,V6C 3B6, Canada
                  </p>
                </div>
                <div>
                  <h4>
                    Email
                  </h4>
                  <p>
                    <a style={{ color: 'white' }} href="mailto:info@imining.com">
                    info@imining.com
                    </a>
                  </p>
                </div>
                <div>
                  <h4>
                    Phone
                  </h4>
                  <p>
                    +1 (844) IMININC (464 - 6462)
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </footer>
      <div className="bottom-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="copyright">
                <p>© Copyright 2024 iMining Technologies Inc. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
export default Footer;