import Hero from "./section/Hero";
// import Reports from "./section/Reports";
import Reports from "../investor/section/Reports"
const Financial = () => {
  return (
    <>
      <Hero />
      <Reports />
    </>
  );
};

export default Financial;
