import Hero from "./section/Hero";
import CompanyList from "./section/CompanyList";
export default function Company() {
  return (
    <>
      <Hero />
      <CompanyList />
    </>
  );
}
