import React, { useRef, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import NavDropdown from "react-bootstrap/NavDropdown";
import { Modal } from "react-bootstrap";

const Header = () => {
  const location = useLocation();
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    // This will trigger the modal to open when the component mounts
    if(!localStorage.getItem('modal')) { 
      setModalShow(true);
    }
  }, []);

  useEffect(() => {
    const body = document.querySelector("nav.destkop");
    if (location.pathname === "/") {
      body.classList.remove("about-page");
    } else {
      body.classList.add("about-page");
    }
  }, [location.pathname]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar expand="lg" className="custom-header destkop">
        <Container>
          <div className="header">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="d-flex align-items-center ">
              <Link to="https://www.aimining.ai/" className="product nav-link">
                <span>
                  <img src="/img/navcircle.gif" />
                </span>
                AI
              </Link>
            </div>
            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
            <Navbar.Collapse
              className="justify-content-center"
              id="basic-navbar-nav"
            >
              <Nav className="">
                <NavDropdown title="About" id="basic-nav-dropdown">
                  <Link to="about" className="nav-link">
                    Overview
                    {/* <span>Learn about your users</span> */}
                  </Link>
                  <Link to="directors" className="nav-link">
                    Board of Director
                    {/* <p>Learn about your users</p> */}
                  </Link>
                </NavDropdown>
                {/* <Link onClick={() => scrollToSection('about')} to="/" className="nav-link">
                  About
                </Link> */}
                {/* <Link onClick={() => scrollToSection('research')} to="/" className="nav-link">
                  Research
                </Link> */}
                <Link to="/our-company" className="nav-link">
                  OUR COMPANIES
                </Link>
              </Nav>
              <Navbar.Brand className="m-0 p-0">
                <Link to="/">
                  <img src="/img/log.svg" />
                </Link>
              </Navbar.Brand>
              <Nav className="">
                <NavDropdown title="News" id="basic-nav-dropdown">
                  <Link
                    to="press-release"
                    className="nav-link"
                  >
                    Press Releases
                  </Link>
                </NavDropdown>
                <NavDropdown title="Investor" id="basic-nav-dropdown">
                  {/* <Link to="investor" className="nav-link">
                    Overview
                  </Link> */}
                  <Link to="financial" className="nav-link">
                    Financial Information
                  </Link>
                  {/* <Link to="stock" className="nav-link">
                    Stock Charts
                  </Link>
                  <Link to="interviews" className="nav-link">
                    Interviews
                  </Link> */}
                </NavDropdown>
                {/* <Link onClick={() => scrollToSection('mission')} to="/" className="nav-link">
                  Mission
                </Link>
                <Link onClick={() => scrollToSection('team')} to="/" className="nav-link">
                  Team
                </Link>
                <Link onClick={() => scrollToSection('press')} to="/" className="nav-link">
                  Press Releases
                </Link> */}
              </Nav>
            </Navbar.Collapse>
            <div className="gap-md d-flex align-items-center ">
              <Link to="contact" className="product nav-link">
                <span>
                  <img src="/img/tel.svg" />
                </span>
                Contact
              </Link>
            </div>
          </div>
        </Container>
      </Navbar>

      <Navbar expand="lg" className="custom-header mobile">
        <Container>
          <div className="header">
            <Navbar.Brand className="m-0 p-0">
              <Link to="/">
                <img src="/img/log.svg" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse
              className="justify-content-end"
              id="basic-navbar-nav"
            >
              <Nav className="">
                <NavDropdown title="About" id="basic-nav-dropdown">
                  <Link to="coming-soon" className="nav-link">
                    Overview
                  </Link>
                  <Link
                    to=""
                    onClick={() => scrollToSection("team")}
                    className="nav-link"
                  >
                    Board of Director
                  </Link>
                </NavDropdown>
                <Link to="our-company" className="nav-link">
                  OUR COMPANIES
                </Link>
                <NavDropdown title="News" id="basic-nav-dropdown">
                  <Link
                    onClick={() => scrollToSection("press")}
                    to="/"
                    className="nav-link"
                  >
                    Press Releases
                  </Link>
                </NavDropdown>
                <NavDropdown title="Investor" id="basic-nav-dropdown">
                  {/* <Link to="coming-soon" className="nav-link">
                    Financial Informal
                  </Link> */}
                  <Link to="financial" className="nav-link">
                    Financial Information
                  </Link>
                  {/* <Link to="coming-soon" className="nav-link">
                    Stock
                  </Link> */}
                  
                  {/* <Link to="comin-soon" className="nav-link">
                    Interviews
                  </Link> */}
                </NavDropdown>
                <Link to="/contact" className="product nav-link">
                  <span>
                    <img src="/img/tel.svg" />
                  </span>
                  Contact
                </Link>
                <Link
                  to="https://www.aimining.ai/"
                  className="product nav-link"
                >
                  <span>
                    <img src="/img/navcircle.gif" />
                  </span>
                  AI
                </Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>

      {/* <Navbar expand="lg" className="custom-header mobile">
        <Container>
          <div className="header">
            <Navbar.Brand className="m-0 p-0">
              <Link to="/">
                <img src="/img/log.svg" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
              <Nav className="">
                <Link onClick={() => scrollToSection('about')} to="/" className="nav-link">
                  About
                </Link>
                <Link onClick={() => scrollToSection('research')} to="/" className="nav-link">
                  Research
                </Link>
                <Link onClick={() => scrollToSection('partners')} to="/" className="nav-link">
                  Partners
                </Link>
                <Link onClick={() => scrollToSection('mission')} to="/" className="nav-link">
                  Mission
                </Link>
                <Link onClick={() => scrollToSection('team')} to="/" className="nav-link">
                  Team
                </Link>
                <Link onClick={() => scrollToSection('press')} to="/" className="nav-link">
                  Press Releases
                </Link>
                <Link to="/contact" className="product nav-link"><span><img src="/img/tel.svg" /></span>Contact</Link>
                <Link onClick={() => scrollToSection('product')} className="product nav-link"><span><img src="/img/navcircle.gif" /></span>Products</Link>
              </Nav>
            </Navbar.Collapse>

          </div>
        </Container>
      </Navbar> */}
    </>
  );
};


function MyVerticallyCenteredModal(props) {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={'static'}
    >
      <Modal.Body className="openDisc">
        <h4>
          Warning: Beware of Fraudulent Websites Claiming to Be iMining
          Technologies Inc (MIN.V)
        </h4>
        <p>
          iMining Technologies Inc wishes to alert our users and the general
          public about fraudulent websites that might claim to be associated
          with us. These websites may use our branding, including our logo,
          company name, and graphics, without our authorization.
        </p>
        <p>
          Please be aware that the official website of iMining Technologies Inc
          is <Link to="">https://imining.com.</Link> Always ensure that this is
          the website address in your browser when looking for information about
          our company. If you encounter a website that looks suspicious or if
          you receive communications that seem untrustworthy, we strongly advise
          you not to provide any personal details.
        </p>
        <p>
          iMining Technologies Inc is actively cooperating with law enforcement
          and other relevant authorities to close down these fraudulent sites.
          If you have any concerns or doubts about the legitimacy of a website
          or communication, please do not hesitate to contact us at{" "}
          <Link to="">info@imining.com</Link>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button variant="secondary" className="press-btn send-mess" onClick={() => {
          props.onHide();
          localStorage.setItem('modal', true);
        }}>
          Yes, I Agree
        </button>
      </Modal.Footer>
    </Modal>
  );
}


export default Header;
