export default function ImgSlide() {
  return (
    <>
      <section className="ImgSlide">
        <div className="container">
          <div className="row">
            <div className="col-12">
             <div className="content">
              <div>
                <img src="/img/about1.svg" />
              </div>
              <div>
                <img src="/img/about2.svg" />
              </div>
              <div>
                <img src="/img/about3.svg" />
              </div>
              <div>
                <img src="/img/about4.svg" />
              </div>
              <div>
                <img src="/img/about5.svg" />
              </div>
             </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
