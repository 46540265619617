import { Link } from "react-router-dom";
export default function Committees() {
  return (
    <>
      <section className="Committees">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div class="text-center">
                <div class="heading">
                  <h4>Committees of the Board of Directors</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="ComiteList">
                <h4>Committees of the Board of Directors</h4>
                <ul className="list-unstyled">
                  <li>
                    <Link to="">
                      <img src="/img/Khurram.jpeg" /> Khurram Shroff
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <img src="/img/Saleem.png" /> Saleem Moosa
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="ComiteList">
                <h4>Compensation Committee and HR</h4>
                <ul className="list-unstyled">
                  <li>
                    <Link to="">
                      <img src="/img/Khurram.jpeg" /> Khurram Shroff
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <img src="/img/Saleem.png" /> Saleem Moosa
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="ComiteList">
                <h4>Audit Committee</h4>
                <ul className="list-unstyled">
                  <li>
                    <Link to="">
                      <img src="/img/Carlton.jpeg" /> Carlton Griffith 
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <img src="/img/Saleem.png" /> Saleem Moosa
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <img src="/img/brad.jpeg" /> Brad McGill
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
