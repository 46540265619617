export default function Hero() {
  return (
    <>
      <section className="AboutHero InvestorHero">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div class="text-center">
                <div class="Hero-Content gap-24">
                  <div class="Frame-Route">
                    <h4>
                      <img src="/img/aboutsubtract.svg" />
                      Stock Charts
                    </h4>
                  </div>
                  <div class="Content">
                    <div>
                      <h4>
                        Trailblazing Digital Transformation through Future
                        Technologies
                      </h4>
                    </div>
                    <div>
                      <p>
                        At iMining Technologies Inc., we're at the forefront of
                        revolutionizing industries through our pioneering
                        approach to digital transformation. Leveraging
                        cutting-edge Future Technologies, we're not just
                        adapting to the future - we're shaping it. Headquartered
                        in Vancouver, Canada, our publicly listed company and
                        its subsidiaries specialize in acquiring, building, and
                        managing innovative tech enterprises poised for global
                        impact. Join us in redefining what's possible in the
                        digital landscape. Contact us at investors@imining.com
                        to explore investment opportunities and be part of our
                        visionary journey.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
