import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import PressReleaseTile from "../PressReleaseTile/PressReleaseTile";
const token = process.env.REACT_APP_PRESS_API_TOKEN;
const url = process.env.REACT_APP_PRESS_API_ENDPOINT;

function PressList() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/iminings?populate=*&filters[category][$eq]=PressRelease&sort=publishedDate:desc`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [token]);
  return (
    <>
      {/* <section className="Hero" style={{ minHeight: "490px" }}>
                <div className="border-frame">
                    <img src="/img/heroframe.svg" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="hero-center-content">
                                <h1 className='m-0'>Press Releases</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      <section className="Press Press-List" id="press">
        <div className="container">
          <video autoPlay loop>
            <source src="/video/press.mp4" type="video/mp4" />
          </video>
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="left-content">
                <h4>
                  <img src="/img/aboutsubtract.svg" />
                  Press release
                </h4>
                <h1>
                  Stay updated with the latest news and announcements from
                  iMining Technologies and its subsidiaries.
                </h1>
              </div>
            </div>
            {/* <div className="col-lg-4 col-sm-12">
              <div className="right-content">
                <div className="videothumbnail">
                  <Link to="">
                    <img src="/img/video.svg" />
                    <h4>Interview with CAIO Mirza Baig</h4>
                  </Link>
                </div>
              </div>
            </div> */}
            <div className="col-lg-12 col-sm-12">
              <div className="horizontimeline">
                <ul className="list-inline">
                  {isLoading
                    ? [0, 1, 2, 3].map((_) => <PressReleaseTile />)
                    : data.map((press, index) => (
                        <PressReleaseTile key={press.id} press={press} />
                      ))}
                </ul>
              </div>

              {/* <div className="horizontimeline">
                <ul className="list-inline">
                  {data.slice(0, 9).map((press) => (
                    <li>
                      <label>
                        {moment(press.attributes.createdAt).format(
                          "MMMM DD, YYYY"
                        )}
                      </label>
                      <p>{press.attributes.title}</p>
                      <Link to={`/press-release-detail/${press.id}`}>
                        learn more
                        <img src="/img/arrowup.svg" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="PressList">
        <div className="container">
          <div className="row">
            {isLoading
              ? [0, 1, 2, 3].map((_) => <PressReleaseTile />)
              : data.map((press, index) => (
                  <PressReleaseTile key={press.id} press={press} />
                ))}
          </div>
        </div>
      </section> */}
    </>
  );
}
export default PressList;
