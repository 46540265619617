import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
const token = process.env.REACT_APP_PRESS_API_TOKEN;

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    subject: "",
    comments: "",
  });
  const [errors, setErrors] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm(formData);
    if (Object.keys(formErrors).length === 0) {
      setShowSuccessMessage(true);
      try {
        const response = await axios.post(
          "https://crm.imining.quantumbases.com/api/i-mining-contact-uses",
          {
            data: formData,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
        setFormData({
          name: "",
          email: "",
          phone: "",
          companyName: "",
          subject: "",
          comments: "",
        });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      console.log("Form has validation errors");
      setErrors(formErrors);
    }
  };

  const validateForm = (formData) => {
    let errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone is required";
    }
    if (!formData.companyName.trim()) {
      errors.companyName = "Company Name is required";
    }
    if (!formData.subject.trim()) {
      errors.subject = "Subject is required";
    }
    if (!formData.comments.trim()) {
      errors.comments = "Comments is required";
    }
    return errors;
  };

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <>
      <section className="Hero-6">
        <div
          className="container"
          style={{ maxWidth: "1216px", padding: "0px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <div className="Hero-Content gap-24">
                  <div className="Frame-Route">
                    <div>
                      <h4>
                        <Link to="/"> Home</Link>
                      </h4>
                    </div>
                    <div>
                      <h4>/&nbsp;&nbsp;Contact</h4>
                    </div>
                  </div>
                  <div className="Content">
                    <div>
                      <h4>We'd love to hear from you.</h4>
                    </div>
                    <div>
                      <p>
                        Connect with us and learn how our professionals can help
                        you see opportunities and maximize the value of your
                        investments.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Custom-Form">
                <form onSubmit={handleSubmit}>
                  <div className="w-100">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name here"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.name && (
                    <div className="alert mb-0 w-100 alert-danger">
                      {errors.name}
                    </div>
                  )}
                  <div className="w-100">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="you@company.com"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.email && (
                    <div className="alert mb-0 w-100 alert-danger">
                      {errors.email}
                    </div>
                  )}
                  <div className="w-100 select-phone">
                    <label className="form-label">Phone</label>
                    <div className="form-group-phone">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter your phone here"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {errors.phone && (
                    <div className="alert mb-0 w-100 alert-danger">
                      {errors.phone}
                    </div>
                  )}
                  <div className="w-100">
                    <label className="form-label">Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your company name here"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                    />
                  </div>

                  {errors.companyName && (
                    <div className="alert mb-0 w-100 alert-danger">
                      {errors.companyName}
                    </div>
                  )}
                  <div className="w-100 select-phone">
                    <label className="form-label">Subject</label>
                    <div className="form-group-phone">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your company subject here"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {errors.subject && (
                    <div className="alert mb-0 w-100 alert-danger">
                      {errors.subject}
                    </div>
                  )}
                  <div className="w-100">
                    <label className="form-label">Comments / Question</label>
                    <textarea
                      className="form-control"
                      placeholder="Leave us a message..."
                      defaultValue={""}
                      name="comments"
                      value={formData.comments}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.comments && (
                    <div className="alert mb-0 w-100 alert-danger">
                      {errors.comments}
                    </div>
                  )}
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      checked
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      You agree to our friendly{" "}
                      <Link to="/privacy-policy">privacy policy</Link>.
                    </label>
                  </div>
                  {showSuccessMessage && (
                    <div className="success-message alert mb-0 w-100 alert-success">
                      Your message has been sent successfully!
                    </div>
                  )}
                  <button type="submit" className="btn send-mess">
                    Send message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Map">
        <div
          className="container"
          style={{ maxWidth: 1216, padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="frame">
                <div className="d-inline-flex align-items-center">
                  <div className="bg-label-circle" />
                  <div className="bg-label-heading">
                    {/* <label>Contact us</label> */}
                  </div>
                </div>
                <div className="grid">
                  <div className="left-content">
                    <div className="colab-content">
                      <div className="Heading">
                        <h4>Chat to our friendly team</h4>
                      </div>
                      <div className="Desc">
                        <p>We’d love to hear from you! Please get in touch.</p>
                      </div>
                    </div>
                  </div>
                  <div className="right-content">
                    <div className="list">
                      <div className="text">
                        <h4>Head office</h4>
                        <p>
                          580 Hornby Street, Suite 750 Vancouver, British
                          Columbia, V6C 3B6, Canada
                        </p>
                      </div>
                    </div>
                    {/* <div className="list">
                      <div className="text">
                        <h4>Calgary Office</h4>
                      </div>
                    </div> */}
                    <div className="list">
                      <div className="text">
                        <h4>Email</h4>
                        <p>info@imining.com</p>
                      </div>
                    </div>
                    <div className="list">
                      <div className="text">
                        <h4>Phone</h4>
                        <p>+1 (844) IMININC (464 - 6462)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ maxWidth: 1216, padding: "0px 12px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="map-frame">
                <iframe
                  height={560}
                  width="100%"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.5348927197406!2d-123.12023612301917!3d49.28520977057468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548671821a4be7af%3A0xc694dc277cb6f7ec!2s580%20Hornby%20St%20%23750%2C%20Vancouver%2C%20BC%20V6C%203B6%2C%20Canada!5e0!3m2!1sen!2s!4v1714387280003!5m2!1sen!2s"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Contact;
