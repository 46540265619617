import Hero from "./section/Hero";
import ImgSlide from "./section/ImgSlide";
import WhoWeAre from "./section/WhoWeAre";
import Corporate from "./section/Corporate";
import Directors from "./section/Directors";

export default function About() {
  return (
    <>
      <Hero />
      <ImgSlide />
      <WhoWeAre />
      <section className="companycard">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="card">
                <h4>Future Tech</h4>
                <p>
                  iMining Technologies Inc. stands at the forefront of
                  technological innovation, orchestrating a dynamic suite of
                  subsidiaries to cultivate, enhance, and scale burgeoning tech
                  enterprises with a global reach.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="card">
                <h4>Artificial Intelligence</h4>
                <p>
                  Our targeted technology firms are at the cutting edge,
                  leveraging Artificial Intelligence to forge groundbreaking,
                  expansible solutions.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="card">
                <h4>Value</h4>
                <p>
                  With a strategic assembly of AI-centric businesses spanning a
                  multitude of sectors, we are committed to propelling a
                  revolution that promises extraordinary value and growth for
                  our customers and investors alike.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="Mission" id="mission">
        <img src="/img/missionlogo.svg" className="missionlogo" />

        <div className="container" style={{ maxWidth: "1312px" }}>
          <div className="row">
            <div className="col-lg-2 col-sm-12">
              <img src="/img/mission1.svg" className="mission1" />
            </div>
            <div className="col-lg-7 col-sm-12">
              <div className="content">
                <h1>Crafting AI systems for positive impact is our mission.</h1>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <img src="/img/mission2.svg" className="mission2" />
            </div>
          </div>

          <div className="row align-items-center secondrow">
            <div className="col-lg-4 col-sm-12 text-end">
              <img src="/img/mission3.svg" className="mission3" />
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="content">
                <p>
                  AI is a powerful tool that demands responsibility. We
                  prioritize human-centeredness, starting with academic research
                  and progressing to responsible AI systems. Our goal is to make
                  AI accessible across various sectors, from enterprises to
                  passion projects like oncology and assistive technologies for
                  children with autism.
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12 text-end">
              <img src="/img/mission4.svg" className="mission4" />
            </div>
          </div>
        </div>
      </section> */}
      {/* <Corporate /> */}
      {/* <Directors /> */}
      {/* <section className="About" id="about">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-12 col-sm-12">
              <div className="left-content">
                <h4>
                  <img src="/img/aboutsubtract.svg" />
                  imining
                </h4>
                <h1>
                  We are an applied research and AI systems development company.
                </h1>
              </div>
            </div>
            <div className="col-xl-7 col-lg-12 col-sm-12">
              <div className="right-content">
                <div className="heading">
                  <div>
                    <h4>
                      Artificial <br /> Intelligence
                    </h4>
                  </div>
                  <div>
                    <img src="/img/imaewithtext.svg" />
                  </div>
                </div>
                <p>
                  iMining Technologies Inc. is a publicly listed technology
                  company which together with its subsidiaries acquires, builds,
                  and manages future technology companies that can scale
                  globally. Our industry specific technology businesses focuses
                  on harnessing Artificial Intelligence to provide innovative
                  and hyper-scalable solutions. We’re establishing a broad
                  portfolio of Ai businesses across all sectors to provide our
                  customers and shareholders with exceptional returns.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
