import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import Spline from "./Spline";

const Hero = () => {


  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      {/* <Spline /> */}
      <section className="Hero">
        {/* <video autoPlay loop>
                    <source src="/video/herovideo.mp4" type="video/mp4" />
                </video> */}
        <div className="border-frame">
          <img src="/img/heroframe.svg" />
        </div>
        <div className="hero-bottom-content">
          <div className="user">
            <ul className="list-inline">
              <li>
                <Link
                  to="https://ca.linkedin.com/company/imininginc"
                  target="_blank"
                >
                  <img src="/img/linkedin.svg" />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/iMiningInc" target="_blank">
                  <img src="/img/twitter.svg" />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/channel/UCCmyxR_woG1f_qXAWPn5dKQ"
                  target="_blank"
                >
                  <img src="/img/youtube.svg" />
                </Link>
              </li>
            </ul>
            {/* <ul className="list-inline">
                                        <li>
                                            <img src="/img/user1.svg" />
                                        </li>
                                        <li>
                                            <img src="/img/user2.svg" />
                                        </li>
                                        <li>
                                            <img src="/img/user3.svg" />
                                        </li>
                                        <li>
                                            <img src="/img/user4.svg" />
                                        </li>
                                        <li>
                                            <img src="/img/user5.svg" />
                                        </li>
                                    </ul> */}
            {/* <div className="content">
                                        <img src="/img/user-circle.svg" />
                                        <h4>20k+</h4>
                                        <p>We have over 20,000 active users engaging with our app</p>
                                    </div> */}
          </div>
          {/* <div className="social">
                                    <ul className="list-inline">
                                        <li>
                                            <Link to="https://ca.linkedin.com/company/imininginc" target="_blank"><img src="/img/linkedin.svg" /></Link>
                                        </li>
                                        <li>
                                            <Link to="https://twitter.com/iMiningInc" target="_blank"><img src="/img/twitter.svg" /></Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.youtube.com/channel/UCCmyxR_woG1f_qXAWPn5dKQ" target="_blank"><img src="/img/youtube.svg" /></Link>
                                        </li>
                                    </ul>
                                </div> */}
          <div className="partner">
            <div className="content">
              {/* <p>looking to be a part of our transformative journey</p> */}
              <Link to="">
                Explore Collaborating Opportunities
              </Link>
              <Link className="mail" to="">
                <img src="/img/mail.svg" />
                info@imining.com
              </Link>
            </div>
            <div className="img">
              <img
                style={{ cursor: "pointer" }}
                onClick={() => scrollToSection("about")}
                src="/img/arrowcircle.svg"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero-center-content">
                <h1>
                  Acquiring, Building & Managing Future-Technology companies
                </h1>
                <button
                  onClick={() => scrollToSection("mission")}
                  className="btn"
                >
                  LEARN MORE
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
