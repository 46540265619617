import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

const endpoint = process.env.REACT_APP_PRESS_API_ENDPOINT;
const PDFViewer = ({ pdfUrl, name }) => {

  const [pdfData, setPdfData] = useState(null);
  const [isLoading, setIsLoading] = useState(Boolean(pdfUrl));

  const fetchPDF = async () => {
    try {
      const response = await fetch(endpoint + pdfUrl);

      const blob = await response.blob();
      setPdfData(URL.createObjectURL(blob));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  useEffect(() => {
    fetchPDF();
  }, []);


  return (
    <div style={{ marginBottom: '60px' }}>
      
      {isLoading ? <Skeleton count={1} height={'500px'}/> : pdfData && (
        <>
          <h4>{name}</h4>
          <iframe
            title="PDF Viewer"
            src={pdfData}
            style={{ width: '100%', height: '900px', border: 'none' }}
          />
        </>
      )}
    </div>
  );
};

export default PDFViewer;